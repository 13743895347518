const sendSocketUpdate = async (user, action, parameters) => {
    return new Promise((resolve, reject) => {
        const socket = window.socket;
        socket.emit('editMML', {
            user,
            action,
            mmlId: store.state.mmlId,
            parameters
        }, (results) => {
            resolve(results);
        });
    });
};


const store = {
    state: {
        notes: [],
        mmlData: {},
        connections: {},
        attachments: [],
        mmlId: '',
        history: []
    },
    mutations: {
        setNotes (state, notes) {
            state.notes = notes;
        },
        setMMLData (state, data) {
            state.mmlData = data;
        },
        updateNote (state, {noteId, text}) {
            const note = state.notes.find(note => note.id === noteId);
            note.note = text;
        },
        removeNote (state, noteId) {
            const note = state.notes.find(note => note.id === noteId);
            state.notes.splice(state.notes.indexOf(note), 1);
        },
        addNote (state, note) {
            state.notes.push(note);
        },
        setParts (state, {parts, customParts}) {
            state.mmlData.mml_parts = parts;
            state.mmlData.mml_custom_parts = customParts;
        },
        updateParts (state, {parts, customParts}) {
            for (const updatedPart of parts) {
                const existingPart = state.mmlData.mml_parts.find(part => part.id === updatedPart.id);
                if (existingPart) {
                    Object.keys(existingPart).forEach(prop => {
                        existingPart[prop] = updatedPart[prop];
                    });
                }
            }
            for (const updatedCustomPart of customParts) {
                const existingPart = state.mmlData.mml_custom_parts.find(part => part.id === updatedCustomPart.id);
                if (existingPart) {
                    Object.keys(existingPart).forEach(prop => {
                        existingPart[prop] = updatedCustomPart[prop];
                    });
                }
            }
        },
        setConnections (state, connections) {
            state.connections = connections;
        },
        setAttachments (state, attachments) {
            state.attachments = attachments;
        },
        setMMLId (state, mmlId) {
            state.mmlId = mmlId;
        },
        addToHistory (state, item) {
            state.history.push(item);
        },
        clearHistory (state) {
            state.history = [];
        },
        reset (state) {
            state.mmlData = {};
            state.notes = [];
            state.attachments = [];
        }
    },
    actions: {
        async getMMLData (context, id) {
            const mmlData = await context.dispatch('sendCommand', {
                action: 'getMMLData',
                parameters: {
                    id
                }
            });
            context.commit('setMMLData', mmlData);
            return mmlData;
        },
        async getNotes (context, partIds) {
            const notes = await context.dispatch('sendCommand', {
                action: 'getMMLNotes',
                parameters: {
                    partIds
                }
            });
            context.commit('setNotes', notes);
        },
        async sendCommand (context, {action, parameters}) {
            const accessKey = 'satmmls';
            const results = await context.dispatch('sendCommand',
                {
                    action,
                    parameters,
                    accessKey
                },
                {
                    root: true
                }
            );
            return results;
        },
        async deleteNote (context, noteId) {
            await context.dispatch('updateMML', {
                action: 'removeNote',
                parameters: {
                    noteId: noteId
                }
            });
        },
        async addNote (context, {partId, property, isCustomPart, note}) {
            await context.dispatch('updateMML', {
                action: 'addNote',
                parameters: {
                    partId,
                    property,
                    isCustomPart,
                    note
                }
            });
        },
        async joinRoom (context, mmlId) {
            context.commit('setMMLId', mmlId);
            const socket = window.socket;

            socket.on('mmlUpdate', async (data) => {
                console.log('RECEIVED MML UPDATE', data);
                if (data.event.mmlId === context.state.mmlId) {
                    switch (data.event.action) {
                    case 'updateParts':
                        context.commit('updateParts', {
                            parts: data.event.parameters.mmlParts,
                            customParts: data.event.parameters.mmlCustomParts
                        });
                        break;
                    case 'removePart':
                        context.commit('setParts', {
                            parts: data.result.parts,
                            customParts: data.result.customParts
                        });
                        break;
                    case 'addPart':
                        context.commit('setParts', {
                            parts: data.result.parts,
                            customParts: data.result.customParts
                        });
                        break;
                    case 'addNote':
                        context.commit('addNote', data.result);
                        break;
                    case 'attachmentsUpdated':
                        await context.dispatch('getAttachments');
                        break;
                    case 'updateMML':
                        context.commit('setMMLData', data.event.parameters);
                        break;
                    case 'removeNote':
                        context.commit('removeNote', data.event.parameters.noteId);
                        break;
                    }
                    context.commit('addToHistory', data.event);
                }
                context.commit('setConnections', data.connections);
            });

            socket.emit('editMML', {
                action: 'join',
                user: context.rootState.dorsettUser,
                parameters: {
                    mmlId: mmlId
                }
            }, () => {
            });
        },
        async leaveRoom (context) {
            context.commit('setMMLId', '');
            const socket = window.socket;
            if (socket && socket._callbacks?.$mmlUpdate) {
                socket.emit('editMML', {
                    action: 'leave',
                    user: context.rootState.dorsettUser
                }, () => {
                    socket.off('mmlUpdate');
                    context.commit('clearHistory');
                });

            }
        },
        async updateMML (context, {action, parameters}) {
            const user = context.rootState.dorsettUser;
            const results = await sendSocketUpdate(user, action, parameters);
            console.log(results);
            return results;
        },

        async saveParts (context, {partIds, customPartIds}) {
            const partsToUpdate = context.state.mmlData.mml_parts.filter(part => partIds.includes(part.id));
            const customPartsToUpdate = context.state.mmlData.mml_custom_parts.filter(part => customPartIds.includes(part.id));
            const results = await context.dispatch('updateMML', {
                action: 'updateParts',
                parameters: {
                    mmlParts: partsToUpdate,
                    mmlCustomParts: customPartsToUpdate
                }
            });
            return results;
        },
        async addPart (context, parameters) {
            const results = await context.dispatch('updateMML', {
                action: 'addPart',
                parameters
            });
            return results;
        },
        async removePart (context, {mmlPartIds, mmlId, custom}) {
            const results = await context.dispatch('updateMML', {
                action: 'removePart',
                parameters: {
                    mmlPartIds,
                    mmlId,
                    custom
                }
            });
            return results;
        },
        /**
         * Fires a socket event making other clients refresh their attachments.
         */
        async attachmentsUpdated (context) {
            await context.dispatch('updateMML', {
                action: 'attachmentsUpdated',
                parameters: {}
            });
        },
        async getAttachments (context) {
            if (context.state.mmlId) {
                const attachments = await context.dispatch('sendCommand', {
                    action: 'getMMLPartAttachments',
                    parameters: {
                        itemId: context.state.mmlId
                    }
                });
                context.commit('setAttachments', attachments);
            }
        }
    },
    getters: {
        allNotes (state) {
            return state.notes;
        },
        note (state) {
            return (partId, property) => {
                return state.notes.find(note => note.property === property && (note.mmlPartId === partId || note.mmlCustomPartId === partId));
            };
        },
        mmlData (state) {
            return state.mmlData;
        },
        parts (state) {
            return state.mmlData.mml_parts || [];
        },
        customParts (state) {
            return state.mmlData.mml_custom_parts || [];
        },
        connections (state) {
            return (mmlId) => {
                return state.connections[mmlId];
            };
        },
        attachments (state) {
            return state.attachments;
        },
        history (state) {
            return state.history;
        }
    }
};

module.exports = store;
