<template lang="pug">
v-app
    router-view

    v-expand-transition
        v-overlay(v-if="showLoading", z-index="999", opacity="1")
            #dots5
                span(v-for="i in new Array(5)")
            .text-center.text-h5.mt-8 Loading
</template>

<script>
import Emitter from "tiny-emitter";
import Vue from "vue";
import { mapGetters } from "vuex";
import _ from "lodash";
import { nanoid } from "nanoid";

Vue.prototype.$emitter = new Emitter();

export default {
    name: "App",

    data: () => ({
        showLoading: true,
        check: null,
    }),

    computed: {
        ...mapGetters(["loggingIn", "loggedIn", "loading"]),
    },

    watch: {
        loggingIn (val) {
            if (!val) {
                let check = nanoid();
                this.check = check;
                setTimeout(() => {
                    console.log("checking logging in", this.loggingIn);
                    if (!this.loggingIn && check === this.check) {
                        if (!this.loggedIn) {
                            this.$router.push("/login");
                        }
                        this.showLoading = false;
                    }
                }, 500);
            }
        },
        loading: _.debounce(function (val) {
            this.showLoading = val;
        }, 500),
    },

    mounted () {
        window.vm = this;

        if (!this.loggingIn) {
            this.showLoading = false;
        }
        window.onbeforeunload = function () {
            this?.$store?.dispatch('disconnectSocket');
        };
    },
};
</script>

<style lang="scss">
/**=== dots5 ===*/
#dots5 {
    display: flex;
    position: absolute;
    justify-content: space-between;
    top: 50%;
    left: 50%;
    height: 50px;
    width: 75px;
    transform: translate(-50%, -50%);
    // margin: -25px 0 0 -25px;
}

#dots5 span {
    // position: absolute;
    width: 10px;
    height: 10px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    -webkit-animation: dots5 1s infinite ease-in-out;
    animation: dots5 1s infinite ease-in-out;
}

@for $c from 1 through 10 {
    #dots5 span:nth-child(#{$c}) {
        animation-delay: -1s + (0.1 * ($c));
    }
}

$dcLight: #009eda;
$dcDark: #00355c;

@keyframes dots5 {
    0% {
        transform: translateY(0px);
        background: white;
    }
    33% {
        transform: translateY(10px);
        background: $dcLight;
    }
    66% {
        transform: translateY(10px);
        background: $dcDark;
    }
    100% {
        transform: translateY(0px);
        background: white;
    }
}
/** END of dots5 */
</style>
