import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
const directories = CONFIG.directories;
const defaultDir = CONFIG.defaultDir;

Vue.use(VueRouter);

let rootDir = 'pages/central/index';

function lazyLoad (view, root = rootDir) {
    return () => import(`@/${root}/${view}.vue`);
}

const serverRoutes = [
    {
        path: '/',
        name: 'Dashboard',
        component: lazyLoad('index', 'pages/central'),
        children: [
            {
                path: 'home',
                name: 'Home',
                component: lazyLoad('index/home')
            },
            {
                path: 'infoscan',
                name: 'InfoScan Dashboard',
                component: lazyLoad('index/infoscan')
            },
            {
                path: 'dccs/permissions',
                name: 'Dorset Controls Central Server',
                component: lazyLoad('dccs/permissions'),
                meta: {
                    role: 'admin'
                }
            },
            {
                path: 'projects/jonasTools',
                name: 'Jonas Tools',
                component: lazyLoad('projects/jonasTools'),
                meta: {
                    role: 'projectsjonas'
                }
            },
            {
                path: 'dccs/serversettings',
                name: 'DCCS Settings',
                component: lazyLoad('dccs/serversettings'),
                meta: {
                    role: 'admin'
                }
            },
            {
                path: 'devops/help',
                name: 'Help',
                component: lazyLoad('devops/help'),
                meta: {
                    role: 'devopshelp'
                }
            },
            {
                path: 'devops/update',
                name: 'Update',
                component: lazyLoad('devops/update'),
                meta: {
                    role: 'devopsupdate'
                }
            },
            {
                path: 'devops/versions',
                name: 'Versions',
                component: lazyLoad('devops/versions'),
                meta: {
                    role: 'devopsversions'
                }
            },
            {
                path: 'devops/customers',
                name: 'Customers',
                component: lazyLoad('devops/customers'),
                meta: {
                    role: 'devopscustomers'
                }
            },
            {
                path: 'devops/users',
                name: 'Internal Users',
                component: lazyLoad('devops/users'),
                meta: {
                    role: 'devopsusers'
                }
            },
            {
                path: 'devops/database',
                name: 'Database',
                component: lazyLoad('devops/database'),
                meta: {
                    role: 'devopsdatabase'
                }
            },
            {
                path: 'devops/communications',
                name: 'Communications',
                component: lazyLoad('devops/communications'),
                meta: {
                    role: 'devopscomms'
                }
            },
            {
                path: 'devops/training',
                name: 'Training',
                component: lazyLoad('devops/training'),
                meta: {
                    role: 'customertraining'
                }
            },
            {
                path: 'devops/downloadclient',
                name: 'Download Client',
                component: lazyLoad('devops/downloadClient'),
                meta: {
                    role: 'devopsdownloadclient'
                }
            },
            {
                path: 'devops/editEmailTemplate',
                name: 'Edit Email Template',
                component: lazyLoad('devops/editEmailTemplate')
            },
            {
                path: 'devops/sendEmail',
                name: 'Send Email',
                component: lazyLoad('devops/sendEmail')
            },
            {
                path: 'devops/editBulletin',
                name: 'Edit Bulletin',
                component: lazyLoad('devops/editBulletin')
            },
            {
                path: 'devops/dependencies',
                name: 'Dependencies',
                component: lazyLoad('devops/dependencies'),
                meta: {
                    role: 'devopsdependencies'
                }
            },
            {
                path: 'sat/download',
                name: 'Download SAT',
                component: lazyLoad('sat/download'),
                meta: {
                    role: 'satdownload'
                }
            },
            {
                path: 'sat/versionmanager',
                name: 'Version Manager',
                component: lazyLoad('sat/versionmanager'),
                meta: {
                    role: 'satversions'
                }
            },
            {
                path: 'sat/bonds',
                name: 'Sales Tool Bonds',
                component: lazyLoad('sat/bonds'),
                meta: {
                    role: 'satbonds'
                }
            },
            {
                path: 'sat/systemtags',
                name: 'Sales Tool System Tags',
                component: lazyLoad('sat/systemtags'),
                meta: {
                    role: 'sat'
                }
            },
            {
                path: 'sat/escalationrules',
                name: 'Sales Tool Escalation Rules',
                component: lazyLoad('sat/escalationrules'),
                meta: {
                    role: 'satescalationrules'
                }
            },
            {
                path: 'sat/partgroups',
                name: 'Sales Tool Part Groups',
                component: lazyLoad('sat/partgroups'),
                meta: {
                    role: 'satpartgroups'
                }
            },
            {
                path: 'sat/labor',
                name: 'Sales Tool Labor',
                component: lazyLoad('sat/labor'),
                meta: {
                    role: 'satlabor'
                }
            },
            {
                path: 'sat/panels',
                name: 'Sales Tool Panels',
                component: lazyLoad('sat/panels'),
                meta: {
                    role: 'satpanels'
                }
            },
            {
                path: 'sat/controllers',
                name: 'Sales Tool Controllers',
                component: lazyLoad('sat/controllers'),
                meta: {
                    role: 'satcontrollers'
                }
            },
            {
                path: 'sat/markup',
                name: 'Sales Tool Markup',
                component: lazyLoad('sat/markup'),
                meta: {
                    role: 'satmarkup'
                }
            },
            {
                path: 'sat/satlabortypes',
                name: 'Sales Tool Labor Types',
                component: lazyLoad('sat/satlabortypes'),
                meta: {
                    role: 'satlabortypes'
                }
            },
            {
                path: 'sat/users',
                name: 'Users',
                component: lazyLoad('sat/users'),
                meta: {
                    role: 'satusers'
                }
            },
            {
                path: 'sat/licensing',
                name: 'Licensing',
                component: lazyLoad('sat/licensing'),
                meta: {
                    role: 'licensing'
                }
            },
            {
                path: 'sat/parts',
                name: 'Parts',
                component: lazyLoad('sat/parts'),
                meta: {
                    role: 'satparts'
                }
            },
            {
                path: 'sat/editmml',
                name: 'MMLs',
                component: lazyLoad('sat/mmleditor'),
                meta: {
                    role: 'satmmls'
                }
            },
            {
                path: 'sat/infoscanexport',
                name: 'SAT Project Export',
                component: lazyLoad('sat/infoscanexport'),
                meta: {
                    role: 'infoscanexport'
                }
            }
        ]
    },
    {
        path: '/auth',
        name: 'central-auth',
        component: lazyLoad('auth/index')
    },
    {
        path: '/login',
        name: 'central-login',
        component: lazyLoad('login', 'pages/central')
    },
    {
        path: '/logout',
        name: 'central-logout',
        component: lazyLoad('logout', 'views')
    }
];

rootDir = 'pages/portal/index';

const portalRoutes = [
    {
        path: '/',
        name: 'Dashboard',
        component: lazyLoad('index', 'pages/portal'),
        children: [
            {
                path: '/',
                name: 'Landing',
                redirect: '/home'
            },
            {
                path: '/home',
                name: 'Home',
                component: lazyLoad('home')
            },
            {
                path: '/customer/downloads',
                name: 'Downloads',
                component: lazyLoad('customer/downloads')
            },
            {
                path: '/customer/resources',
                name: 'Resources',
                component: lazyLoad('customer/resources')
            },
            {
                path: '/customer/resources/category',
                name: 'Training',
                component: lazyLoad('customer/trainingcategory')
            },
            {
                path: '/customer/contact',
                name: 'Contact',
                component: lazyLoad('customer/contact')
            },
            {
                path: '/customer/analytics',
                name: 'Analytics',
                component: lazyLoad('customer/analytics')
            },
            {
                path: 'customer/analytics/flow',
                name: 'Flow Analytics',
                component: lazyLoad('customer/analytics/flow')
            },
            {
                path: '/customer/userManagement',
                name: 'User Management',
                component: lazyLoad('customer/userManagement'),
                meta: {
                    role: 'admin'
                }
            }
        ]
    },
    {
        path: '/auth',
        name: 'portal-auth',
        component: lazyLoad('auth/index')
    },
    {
        path: '/login',
        name: 'portal-login',
        component: lazyLoad('login', 'pages/portal')
    },
    {
        path: '/logout',
        name: 'portal-logout',
        component: lazyLoad('logout', 'views')
    },
    {
        path: '/security-bulletins',
        name: 'central-security',
        component: lazyLoad('securitybulletins'),
        meta: {
            bulletins: []
        }

    }
];

const allRoutes = {
    central: serverRoutes,
    portal: portalRoutes
};

let routes;
let host = window.location.host;
directories.forEach((dir) => {
    if (host.match(dir)) {
        if (allRoutes[dir]) {
            routes = allRoutes[dir];
        }
    }
});
if (!routes) {
    routes = allRoutes[defaultDir];
}

const router = new VueRouter({
    mode: 'hash',
    routes
});

const emptyFn = Function.prototype;

const originalPush = router.push;
router.originalPush = originalPush;
router.push = function push (location, onComplete, onAbort) {
    return new Promise((resolve, reject) => {
        const handleError = (err) => {
            if (err.name !== 'NavigationDuplicated') {
                (onAbort || reject)(err);
            } else {
                (onComplete || emptyFn)();
            }
        };
        try {
            originalPush.call(this, location, () => {
                (onComplete || resolve)();
            }, handleError);
        } catch (err) {
            handleError(err);
        }
    });
};

router.beforeEach(async (to, from, next) => {
    const publicPages = ['/login', '/security-bulletins'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = store.getters.loggedIn;
    const loggingIn = store.getters.loggingIn;

    if (to.path !== '/sat/editmml') {
        store.dispatch('mmls/leaveRoom');
    }

    // console.log(JSON.stringify(to.query));
    // trying to access a restricted page + not logged in
    // redirect to login page
    if (loggingIn) {
        if (!authRequired) {
            next();
        } else {
            next(false);
        }
    } else if (authRequired && !loggedIn) {
        return next({
            path: '/login',
            query: {
                redirect: to.fullPath === '/' ? undefined : to.fullPath
            }
        });
    } else {
        let meta = to.meta;
        let role = meta && meta.role;
        await store.dispatch('startup');
        // If the user is not an admin
        if (role) {
            if (!store.getters.accessible(role)) {
                store.commit('error', `Access Denied for "${to.name}"`);
                return next({
                    path: '/'
                });
            }
        }
        if (to.path === from.path && !to.query) {
            next(false);
        } else {
            next();
        }
    }
});

export default router;
