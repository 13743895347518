import axios from 'axios';

const protocol = window.location.protocol;
const hostname = window.location.hostname;
const port = CONFIG.dev ? CONFIG.devApiPort : window.location.port;
const url = `${protocol}//${hostname}:${port}/`;
axios.defaults.baseURL = url;

const instance = axios.create({
    baseURL: url
});

export default instance;
